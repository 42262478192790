<template>
  <BaseCard :styles="{ borderRadius: '15px', padding: '0' }" :style="cardStyle">
    <router-link
      :to="data && data.link ? data.link : link"
      class="card__content"
    >
      <div class="card__header">
        <CircleButton
          height="36px"
          width="36px"
          :bg-color="bgColor"
          :color="color"
        />
        <div class="card-free-button" v-if="status">
          {{ statusLabel }}
        </div>
      </div>
      <h4>{{ title }}</h4>
      <div class="card-description" v-html="description"></div>
      <!-- <span class="card__views">
        <div class="card_views_details">
          <font-awesome-icon icon="eye" />
          <span>{{ views }}</span>
        </div>
      </span> -->
    </router-link>
    <div class="card__footer">
      <a @click="bookmarkLink(data._id)">{{ bookmark }}</a>
      <font-awesome-icon
        icon="times"
        @click="removeAction(data._id)"
        v-if="removeAction"
      />
    </div>
  </BaseCard>
</template>

<script>
import CircleButton from "@/components/Buttons/CircleButton.vue";
import BaseCard from "@/components/Cards/BaseCard.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faTimes } from "@fortawesome/free-solid-svg-icons";

library.add(faEye, faTimes);
const colors = require("/colors.config.json");
export default {
  name: "Card",
  components: { CircleButton, BaseCard },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    removeAction: {
      type: [Function, Boolean],
      default: false,
    },
    title: {
      type: String,
      default: "1. Checkliste: Bewerbung",
    },
    statusLabel: {
      type: String,
      default: "Free",
    },
    status: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "Das brings du mit!",
    },
    views: {
      type: Number,
      default: 0,
    },
    bookmark: {
      tyoe: String,
      default: "Bookmark",
    },
    bookmarkLink: {
      type: Function,
    },
    bgColor: {
      type: String,
      default: `${colors.white}`,
    },
    color: {
      type: String,
      default: `${colors.white}`,
    },
    link: {
      type: String,
      default: "#",
    },
  },
  computed: {
    cardStyle() {
      return {
        "--background-color": this.bgColor,
        "--color": this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-card {
  height: 100%;
}

.card__content {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
  height: calc(100% - 40px);
  min-height: 180px;
  color: #2c3e50;
  text-decoration: none;

  @media (min-width: 1200px) {
    min-height: 200px;
  }

  @media (min-width: 1600px) {
    min-height: 220px;
  }

  .card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .card-free-button {
      background: var(--background-color);
      width: 60px;
      height: 36px;
      color: var(--color);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 10px;
    white-space: normal;
    word-wrap: break-word;
    hyphens: auto;
    text-align: left;
  }

  .card-description {
    ::v-deep {
      * {
        margin: 0 0 10px;
        font-size: 12px;
        white-space: normal;
        word-wrap: break-word;
        hyphens: auto;
        text-align: left;
      }
    }
  }

  .card__views {
    font-size: 12px;
    margin-top: auto;
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    .card_views_details {
      display: flex;
      margin-left: auto;

      svg {
        display: inline-block;
        width: 20px;
        height: 16px;
        margin-right: 2px;
      }
    }
  }

  &:hover {
    background: var(--background-color);
    cursor: pointer;
    transition: all 0.3s ease;

    h4 {
      color: var(--color);
    }

    p {
      color: var(--color);
    }

    .card-description {
      color: var(--color);
      
      ::v-deep {
        * {
          color: var(--color);
        }
      }
    }

    .card-arrow {
      transition: all 0.3s ease;
      background: var(--color) !important;
      color: var(--background-color) !important;
    }

    .card_views_details {
      color: var(--color);
    }
  }
}

.card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background-color);
  margin-top: auto;
  padding: 10px 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 80%;
  font-weight: 400;
  height: 40px;

  a {
    color: var(--color);
    text-decoration: underline;
    word-break: break-word;
    cursor: pointer;
    transition: color 300ms ease-out;

    &:hover {
      color: var(--color);
    }
  }

  svg {
    font-size: 0.925rem;
    color: var(--color);
    cursor: pointer;
  }
}
</style>
