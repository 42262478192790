<template>
  <div class="bookmark client-content-padding">
    <div class="bookmark-row">
      <div class="bookmark-column" v-if="isAuthenticated">
        <div v-if="bookmarks.length == 0">
          {{labels.no_bookmarks_added}}
        </div>
        <div v-else class="content" v-for="root in roots" :key="root._id">
          <div
            class="content__title"
            v-if="desiredModuleId(root._id).length != 0"
          >
            {{ root.title }}: {{ textReplace(root.description) }}
          </div>
          <div class="content__cards">
            <div
              class="card-wrapper"
              v-for="(bookmark, indexBook) in desiredModuleId(root._id)"
              :key="indexBook"
            >
              <Card
                :title="`${indexBook + 1}. ${bookmark.text}`"
                :bg-color="root.color"
                :data="bookmark"
                :bookmarkLink="updateBookmark"
                :removeAction="removeBookmark"
                :link="getLinkToBookmark(bookmark._id)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="warning" v-else>
        <h1>{{ labels.please_authorize_to_watch_the_content }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Cards/Card.vue";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
export default {
  name: "Bookmarks",
  components: {
    Card,
  },
  data() {
    return {
      roots: [],
      bookmarks: [],
    };
  },
  computed: {
    ...mapGetters('helper', ['labels']),
    ...mapState("account", {
      stateBookmarks: "bookmarks"
    }),
    isAuthenticated() {
      return this.$store.getters["user/get"].isAuthenticated;
    },
  },
  methods: {
    ...mapActions("notification", ["addNotification"]),
    ...mapActions("account", ["getBookmarkList"]),
    ...mapActions("account", ["getBookmark"]),
    ...mapActions("account", ["deleteBookmark"]),
    ...mapMutations("account", ["updateBookmarks"]),
    updateBookmark(id) {
      this.$router.push({
        name: "bookmark",
        params: {
          bookmark_id: id
        },
      });
    },
    loadBookmarks() {
      this.getBookmarkList()
        .then((res) => {
          if (res.success) {
            this.roots = res.data.roots;
          }

          if (res.error) {
            this.addNotification({ variant: "danger", msg: res.error });
          }
        })
        .catch((err) => console.log(err));
    },
    desiredModuleId(id) {
      let bookmarks = this.bookmarks.filter((item) => item.module._id == id);
      return bookmarks;
    },
    removeBookmark(id) {
      this.deleteBookmark({ id: id });
    },
    textReplace(text) {
      text = text.replace("<p>", "");
      text = text.replace("</p>", "");
      return text;
    },
    getLinkToBookmark(id) {
      return this.$router.resolve({ 
        name: 'bookmark',
        params: { bookmark_id: id },
      }).href;
    }
  },
  mounted() {
    this.loadBookmarks();
  },
  watch: {
    stateBookmarks: {
      deep: true,
      handler(newVal) {
        this.bookmarks = newVal;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bookmark {
  .content {
    &__title {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 15px;

      @media (min-width: 992px) {
        font-size: 24px;
      }

      @media (min-width: 1200px) {
        font-size: 32px;
        margin: 0 0 25px;
      }

      @media (min-width: 1600px) {
        font-size: 40px;
        margin: 0 0 35px;
      }
    }

    &__cards {
      display: flex;
      flex-wrap: wrap;
      // margin-bottom: 15px;
      margin-left: -7.5px;
      margin-right: -7.5px;

      @media (min-width: 1200px) {
        // margin-bottom: 30px;
        margin-left: -15px;
        margin-right: -15px;
      }

      @media (min-width: 1600px) {
        // margin-bottom: 50px;
        margin-left: -25px;
        margin-right: -25px;
      }

      .card-wrapper {
        padding: 0 7.5px;
        flex: 0 0 50%;
        width: 100%;
        max-width: 50%;
        margin-bottom: 15px;
        transition: all 0.3s ease;

        @media (min-width: 576px) {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
        }

        @media (min-width: 992px) {
          flex: 0 0 25%;
          max-width: 25%;
        }

        @media (min-width: 1200px) {
          padding: 0 15px;
          margin-bottom: 30px;
        }

        @media (min-width: 1600px) {
          flex: 0 0 20%;
          max-width: 20%;
          padding: 0 25px;
          margin-bottom: 50px;
        }
      }
    }
  }
}
.warning {
  display: flex;
  justify-content: center;
}
</style>
